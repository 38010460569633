:root {
    --brand-primary: #007bff;
    --brand-secondary: #6c757d;
}

.bg-brand-primary {
    background-color: var(--brand-primary);
}

.bg-brand-secondary {
    background-color: var(--brand-secondary);
}

@import '../../../node_modules/@ng-select/ng-select/themes/default.theme.css';

/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/bootstrap/scss/mixins/banner';
@import '../../../node_modules/bootstrap/scss/functions';

@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/variables-dark';

// $body-bg: #fbfbfb;

$headings-font-family: 'Roboto', sans-serif;
$headings-font-weight: 700;
$font-family-base: 'Roboto', sans-serif;
$btn-border-width: 2px;
$btn-border-radius: 0.25rem;
$btn-border-radius-sm: 4px;
$btn-border-radius-lg: 4px;
$input-border-width: 2px;
$input-border-radius: 0.25rem;
$input-border-radius-sm: 0.25rem;
$input-border-radius-lg: 0.25rem;

$btn-font-weight: 700;

// $primary: var(--brand-primary);
// $secondary: var(--brand-secondary);

.ng-select {
    .ng-select-container {
        font-weight: 700 !important;
    }

    &.ng-invalid.ng-touched {
        .ng-select-container {
            border-color: $danger !important;
        }
    }

    .ng-select.ng-select-opened > .ng-select-container {
        border-color: $input-border-color !important;
    }

    .ng-select-container {
        height: 46px !important;
        border-width: 2px !important;
        border-color: $input-border-color !important;
    }
}

ngb-datepicker {
    background-color: #ffffff;
    .ngb-dp-weekdays {
        margin-bottom: 0.25rem;
    }
    .ngb-dp-day,
    .ngb-dp-week-number {
        width: 3rem;
        height: 3rem;
        text-align: center;
    }

    .ngb-dp-weekday {
        width: 3rem;
        text-transform: uppercase;
        color: #333;
        font-style: normal;
    }

    .custom-day {
        text-align: center;
        padding: 0.5rem 0.5rem;
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        font-weight: 300;
    }
    .custom-day.focused {
        background-color: #e6e6e6;
    }

    .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
    }

    .custom-day.available {
        background-color: lighten($primary, 42);
        color: $primary;
        font-weight: 700;

        &:hover {
            background-color: lighten($primary, 35);
        }

        &.selected {
            background-color: $primary;
            color: #ffffff;
        }
    }

    .custom-day.disabled {
        color: #ccc;
    }
}

@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../../../node_modules/bootstrap/scss/root';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/type';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/containers';
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/transitions';
@import '../../../node_modules/bootstrap/scss/dropdown';
@import '../../../node_modules/bootstrap/scss/button-group';
@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/navbar';
@import '../../../node_modules/bootstrap/scss/card';
@import '../../../node_modules/bootstrap/scss/accordion';
@import '../../../node_modules/bootstrap/scss/breadcrumb';
@import '../../../node_modules/bootstrap/scss/pagination';
@import '../../../node_modules/bootstrap/scss/badge';
@import '../../../node_modules/bootstrap/scss/alert';
@import '../../../node_modules/bootstrap/scss/progress';
@import '../../../node_modules/bootstrap/scss/list-group';
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/toasts';
@import '../../../node_modules/bootstrap/scss/modal';
@import '../../../node_modules/bootstrap/scss/tooltip';
@import '../../../node_modules/bootstrap/scss/popover';
@import '../../../node_modules/bootstrap/scss/carousel';
@import '../../../node_modules/bootstrap/scss/spinners';
@import '../../../node_modules/bootstrap/scss/offcanvas';
@import '../../../node_modules/bootstrap/scss/placeholders';

// Helpers
@import '../../../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../../../node_modules/bootstrap/scss/utilities/api';

label.form-label {
    font-weight: 900;
}

a {
    text-decoration: none;
}

.ng-invalid.ng-touched + .invalid-feedback {
    display: block !important;
}

.ng-invalid.ng-touched {
    @extend .is-invalid;
}

.is-invalid + .invalid-feedback {
    display: block !important;
}

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';

.page {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 150px;
        margin-bottom: 200px;
    }
}

.page-section {
    background-color: #ccc;
    padding: 5rem 0;
    &--enquiry {
        background-color: #a92f2f;
        color: #ffffff;
    }

    &--air-con {
        background-color: #222b5e;
        color: #ffffff;
    }

    &--general-enquiry {
        background-color: #f8f9fa;
    }

    &--form {
        background-color: #f8f9fa;
    }
}

.form-container {
    background-color: #ffffff;
    padding: 2rem;
    color: var(--bs-body-color);
    @extend .rounded-3;
    @extend .shadow;
}
